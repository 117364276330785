body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain;
}
.scanner-container {
  align-items: center;
  display: flex;
  height: 100px;
  max-height: 100px;
  min-height: 100px;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}
.scanner-barcode-picker {
  height: 100%;
  width: 100%;
  position: relative;
}
.scanner-laser {
  vertical-align: middle;
  border-style: none;
  max-width: 75%;
  max-height: 10%;
  z-index: 10;
  -webkit-transition: opacity .25s ease;
  transition: opacity .25s ease;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  pointer-events: none;
  margin: auto;
  -webkit-animation-duration: .25s;
          animation-duration: .25s;
}
.invoice-print { 
  -webkit-print-color-adjust: exact;
  color: black;
  font-size: 14px;
  word-wrap: break-word;
}
.snapshot-print {
  padding: 20mm 0mm 0mm 0mm;
}

td.invoice {
  font-size: 14px;
}
#root {
  width: 100vw;
  height: 100vh;
}
.button-text {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bus-font {
  font-size: 1.3125em;
}
.large-font {
  font-size: 1.8em;
}
.mid-large-font {
  font-size: 1.6em;
}
.mid-font {
  font-size: 1.4em;
}
.mid-small-font {
  font-size: 1.2em;
}
.small-font {
  font-size: 1.0em;
}
.font-heighlight {
  color: green;
}
.font-error {
  color: red;
}
.font-warning {
  color: orange;
}
.font-primary {
  color: #1890ff;
}
.font-blue {
  color: #3F51B5;
}
.font-brown {
  color: #795548;
}
.no-border-pnael {
  background: #f7f7f7;
  border-radius: 4px;
  margin-bottom: 24px;
  border: 0px;
  overflow: hidden;
}
.vertical-radio {
  display: block;
  height: 30px;
  line-height: 30px;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.freeze-scroll {
  overflow: hidden;
}
.freeze-scroll-x {
  overflow-x: hidden;
}
.self-scroll {
  overflow-y: auto;
}
.self-scroll-x {
  overflow-x: auto;
}
.hover-selectable:hover { 
  cursor: pointer;
  background-color: #E0F7FA;
}
.clear-margin {
  margin: 0px;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}
.text-center {
  text-align: center;
}
.flex { /* Safari */
    display: flex;
    flex-direction: column; /* Safari */
}
.flex-fill { /* Safari */
    flex: 1 1;
}
.flex-row {
    flex-direction: row; /* Safari */
}
.flex-column {
    flex-direction: column; /* Safari */
}
.flex-center { /* Safari */
    justify-content: center;
}
.flex-wrap { /* Safari */
    flex-wrap: wrap;
}
.flex-space-between { /* Safari */
    justify-content: space-between;
}
.flex-space-around { /* Safari */
    justify-content: space-around;
}
.flex-align-center {
    align-items: center;
    -webkit-align-items: center; /* Safari */
}
.flex-align-start {
    align-items: flex-start;
    -webkit-align-items: flex-start; /* Safari */
}
.flex-align-end {
    align-items: flex-end;
    -webkit-align-items: flex-end; /* Safari */
}
.flex-end {
    justify-content: flex-end;
    -webkit-justify-content: flex-end; /* Safari */
}
.margin {
    margin: 16px;
}
.margin-half {
    margin: 8px;
}
.margin-top {
    margin-top: 16px;
}
.margin-top-half {
  margin-top: 8px;
}
.margin-bottom {
    margin-bottom: 16px;
}
.margin-bottom-half {
  margin-bottom: 8px;
}
.margin-horizontal {
    margin: 0px 16px;
}
.margin-horizontal-half {
  margin: 0px 8px;
}
.margin-vertical {
    margin: 16px 0px;
}
.margin-right {
    margin-right: 16px;
}
.margin-left {
    margin-left: 16px;
}
.padding-half {
   padding: 8px;
}
.padding {
    padding: 16px;
}
.padding-top {
  padding-top: 16px;
}
.padding-top-half {
    padding-top: 8px;
}
.padding-bottom {
    padding-bottom: 16px;
}
.padding-bottom-half {
  padding-bottom: 8px;
}
.padding-left {
    padding-left: 16px;
}
.padding-left-half {
  padding-left: 8px;
}
.padding-right {
    padding-right: 16px;
}
.padding-right-half {
  padding-right: 8px;
}
.padding-horizontal {
    padding: 0px 16px;
}
.padding-horizontal-half {
    padding: 0px 8px;
}
.padding-vertical {
    padding: 16px 0px;
}
.padding-vertical-half {
  padding: 8px 0px;
}

